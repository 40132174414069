/*!
 * Fuel UX v3.17.0 
 * Copyright 2012-2018 ExactTarget
 * Licensed under the BSD-3-Clause license (https://github.com/ExactTarget/fuelux/blob/master/LICENSE)
 */

@charset "UTF-8";
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
@font-face {
  font-family: "fuelux";
  src: url("../fonts/fuelux.eot");
  src: url("../fonts/fuelux.eot?#iefix") format("embedded-opentype"), url(data:font-woff;base64,d09GRk9UVE8AAAUUAAsAAAAABuAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABDRkYgAAABCAAAAegAAAIisWS2RkZGVE0AAALwAAAAGQAAABxvEtzhR0RFRgAAAwwAAAAdAAAAIAA4AARPUy8yAAADLAAAAEoAAABgL7lMbGNtYXAAAAN4AAAAPAAAAUrAGiK3aGVhZAAAA7QAAAAvAAAANgDSVuNoaGVhAAAD5AAAABwAAAAkBCkCA2htdHgAAAQAAAAAGgAAABoHmgL4bWF4cAAABBwAAAAGAAAABgALUABuYW1lAAAEJAAAAN0AAAF3kxoWmXBvc3QAAAUEAAAAEAAAACAAAwABeJw90M9rE0EUB/D30uzusLtuNTqiGIs/+gt6ifVHtgfRHqQHLTYumEsrpSSWRVNozNJc2t6S2K2bBATbS0tLe9jDUlJ6aS85hFT8H6SCFPwTZiCIbpnq6fMe8+Y7j0GIRgERlbdO9r1TBIwAwijvi/D+Ln4tuqqjq3e5erRHhetW7I/r/i90xfN4ndelOHztjgNcjMP3S3HQ41iIgX4WQ6AbrsJN6IUhGIYReApjMA6WM2c/SyQSgnuCYcF9wQPBQ8EjQVJgCkbEvudbA2AZK/gRV9DFVfyEHlaxBhpiJPlk7GX6TebdhxWDDfEb9JbaGezI9Laa74zTOyqTn9O7KosFtFdlg0ymfeE5e0X7VTZaogOqwXz+OOwM/pqbZz2vs+VwyGC/2YXwjsGWf4YBxrdfYZjhMT9M/veQwU7ZHD3MNianstmpyUb28KjROOox+OcrLnV1Nfysy0AQ6YD5Yjpf+jJxLB0Eza2WR0zFruSW5otEq7W2m3v7pJ2SFvKLubJNNPvAaVZa5ET27LXcxjzRkopVS29n9ki7Le0s+ItBmWjHE9KMnXasCjlRAs9f290gWskqpGczJNWWNnfW/WpAtB9Kq9Qs7M+SVErKb+bW7SrRgpmttGcRU64ES35xl2h/Ac9Ys4h4nGNgYGBkAILzccd+gelbXJ9hNABYswg7AAAAeJxjYGRgYOADYgkGEGBiYARCLiBmAfMYAAS4AD0AAAB4nGNgZmJgnMDAysDB6MOYxsDA4A6lvzJIMrQwMDAxsHIywIEAgskQkOaawuDwgOEBJ+OD/w8Y9Bj//v8LFGaEK1AAQkYALokNlwAAeJxjYGBgZoBgGQZGBhBwAfIYwXwWBg0gzQakGRmYGBgecP7/D1LwgAFEKzBC1QMBIxsDgjNCAQBlrAaweJxjYGRgYADiH2cX2sfz23xl4GZiAIHzt7g+w2lmBmbGv4x/gVwOBrA0AFSyC6wAeJxjYGRgYPzLwMCgx8QAAkA2IwMqYAIANwYCMQIAAAACAAADARUBhwGDAQ8AWAADAAMAXACnAAAAAFAAAAsAAHicbY49asNAEIU/2bJMsEmX1JsipYS0BAwuU/gAKdy7WAuDkPDagtzDkD5djpED5AQ5TZ7kKVJ4l9n59vHmB1jyQcJwEjLujSfMeTae8sTROJXn03jGgm/jTPdXziS9k7IcqwaeqOOD8ZRXcuNUnovxjEe+jDPpP+zpCTR632Hfh6ZXfpNUS2rYEfUNdd/sBBs6Ws5jjnIEHJ6CUnmt+N/sqnitsVJ4eSpe1KJrz5su1sH5onRrdx0p8Pkq92Uly82VtpIiJw7jCk7NhrFsQzwdutZVRXm78A9U7zY2AAAAeJxjYGZABowMaAAAAI4ABQ==) format("woff"), url("../fonts/fuelux.ttf") format("truetype"), url("../fonts/fuelux.svg#fuelux") format("svg");
  font-weight: normal;
  font-style: normal;
}
.fuelux [class^="fueluxicon-"]:before,
.fuelux [class*=" fueluxicon-"]:before {
  font-family: "fuelux" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fuelux .fueluxicon-loader-full:before {
  content: "\e000";
}
.fuelux .fueluxicon-loader-1:before {
  content: "\e001";
}
.fuelux .fueluxicon-loader-2:before {
  content: "\e002";
}
.fuelux .fueluxicon-loader-3:before {
  content: "\e003";
}
.fuelux .fueluxicon-loader-4:before {
  content: "\e004";
}
.fuelux .fueluxicon-loader-5:before {
  content: "\e005";
}
.fuelux .fueluxicon-loader-6:before {
  content: "\e006";
}
.fuelux .fueluxicon-loader-7:before {
  content: "\e007";
}
.fuelux .fueluxicon-loader-8:before {
  content: "\e008";
}
.fuelux .fueluxicon-bullet:before {
  content: "\e009";
}
.fuelux .radio-inline,
.fuelux .checkbox-inline {
  padding-left: 0;
}
.fuelux .radio-inline.highlight,
.fuelux .checkbox-inline.highlight {
  left: -8px;
}
.fuelux .radio-inline label,
.fuelux .checkbox-inline label {
  margin-bottom: 0;
}
label.fuelux .radio:hover:before,
label.fuelux .checkbox:hover:before,
label.fuelux .radio-inline:hover:before,
label.fuelux .checkbox-inline:hover:before,
.input-label.fuelux .radio:hover:before,
.input-label.fuelux .checkbox:hover:before,
.input-label.fuelux .radio-inline:hover:before,
.input-label.fuelux .checkbox-inline:hover:before,
.fuelux .radio label:hover:before,
.fuelux .checkbox label:hover:before,
.fuelux .radio-inline label:hover:before,
.fuelux .checkbox-inline label:hover:before,
.fuelux .radio .input-label:hover:before,
.fuelux .checkbox .input-label:hover:before,
.fuelux .radio-inline .input-label:hover:before,
.fuelux .checkbox-inline .input-label:hover:before {
  box-shadow: inset 0px 0px 2px 1px rgba(91, 157, 217, 0.7), 0px 0px 5px 0px rgba(91, 157, 217, 0.7);
}
.fuelux .radio label,
.fuelux .checkbox label,
.fuelux .radio-inline label,
.fuelux .checkbox-inline label,
.fuelux .radio .input-label,
.fuelux .checkbox .input-label,
.fuelux .radio-inline .input-label,
.fuelux .checkbox-inline .input-label,
label.fuelux .radio,
label.fuelux .checkbox,
label.fuelux .radio-inline,
label.fuelux .checkbox-inline {
  cursor: pointer;
  font-weight: normal;
}
.fuelux .radio.highlight input[type="checkbox"]:not(.sr-only) ~ label,
.fuelux .checkbox.highlight input[type="checkbox"]:not(.sr-only) ~ label,
.fuelux .radio-inline.highlight input[type="checkbox"]:not(.sr-only) ~ label,
.fuelux .checkbox-inline.highlight input[type="checkbox"]:not(.sr-only) ~ label,
.fuelux .radio.highlight input[type="radio"]:not(.sr-only) ~ label,
.fuelux .checkbox.highlight input[type="radio"]:not(.sr-only) ~ label,
.fuelux .radio-inline.highlight input[type="radio"]:not(.sr-only) ~ label,
.fuelux .checkbox-inline.highlight input[type="radio"]:not(.sr-only) ~ label {
  padding: 4px;
  border-radius: 4px;
}
.fuelux .radio.highlight input[type="checkbox"]:not(.sr-only):checked ~ label,
.fuelux .checkbox.highlight input[type="checkbox"]:not(.sr-only):checked ~ label,
.fuelux .radio-inline.highlight input[type="checkbox"]:not(.sr-only):checked ~ label,
.fuelux .checkbox-inline.highlight input[type="checkbox"]:not(.sr-only):checked ~ label,
.fuelux .radio.highlight input[type="radio"]:not(.sr-only):checked ~ label,
.fuelux .checkbox.highlight input[type="radio"]:not(.sr-only):checked ~ label,
.fuelux .radio-inline.highlight input[type="radio"]:not(.sr-only):checked ~ label,
.fuelux .checkbox-inline.highlight input[type="radio"]:not(.sr-only):checked ~ label {
  background-color: #e6e6e6;
  opacity: 1;
  z-index: 0;
}
.fuelux .radio input[type="checkbox"]:not(.sr-only),
.fuelux .checkbox input[type="checkbox"]:not(.sr-only),
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only),
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only),
.fuelux .radio input[type="radio"]:not(.sr-only),
.fuelux .checkbox input[type="radio"]:not(.sr-only),
.fuelux .radio-inline input[type="radio"]:not(.sr-only),
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  /* for keyboard tabbing */
}
.fuelux .radio input[type="checkbox"]:not(.sr-only) ~ label,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only) ~ label,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only) ~ label,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only) ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only) ~ label,
.fuelux .checkbox input[type="radio"]:not(.sr-only) ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only) ~ label,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only) ~ label {
  padding-left: 0;
}
.fuelux .radio input[type="checkbox"]:not(.sr-only) ~ label:before,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only) ~ label:before,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only) ~ label:before,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only) ~ label:before,
.fuelux .radio input[type="radio"]:not(.sr-only) ~ label:before,
.fuelux .checkbox input[type="radio"]:not(.sr-only) ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only) ~ label:before,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only) ~ label:before {
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
  border: 1px solid #aaaaaa;
  color: #FFF;
  font-size: 9px;
  width: 14px;
  height: 14px;
  padding: 0;
  margin-top: 0;
  margin-bottom: -2px;
  cursor: pointer;
  display: inline-block;
  text-align: left;
  z-index: 2;
  content: "";
}
.fuelux .radio input[type="checkbox"]:not(.sr-only):active ~ label,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):active ~ label,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):active ~ label,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):active ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only):active ~ label,
.fuelux .checkbox input[type="radio"]:not(.sr-only):active ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):active ~ label,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):active ~ label,
.fuelux .radio input[type="checkbox"]:not(.sr-only):focus ~ label,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):focus ~ label,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):focus ~ label,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):focus ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only):focus ~ label,
.fuelux .checkbox input[type="radio"]:not(.sr-only):focus ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):focus ~ label,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):focus ~ label {
  color: #333333;
  color: #39b3d7;
  cursor: pointer;
}
.fuelux .radio input[type="checkbox"]:not(.sr-only):active ~ label:before,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):active ~ label:before,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):active ~ label:before,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):active ~ label:before,
.fuelux .radio input[type="radio"]:not(.sr-only):active ~ label:before,
.fuelux .checkbox input[type="radio"]:not(.sr-only):active ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):active ~ label:before,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):active ~ label:before,
.fuelux .radio input[type="checkbox"]:not(.sr-only):focus ~ label:before,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):focus ~ label:before,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):focus ~ label:before,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):focus ~ label:before,
.fuelux .radio input[type="radio"]:not(.sr-only):focus ~ label:before,
.fuelux .checkbox input[type="radio"]:not(.sr-only):focus ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):focus ~ label:before,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):focus ~ label:before {
  box-shadow: inset 0px 0px 2px 1px #5b9dd9, 0px 0px 5px 0px #5b9dd9;
  outline: none;
}
.fuelux .radio input[type="checkbox"]:not(.sr-only):active ~ label,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):active ~ label,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):active ~ label,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):active ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only):active ~ label,
.fuelux .checkbox input[type="radio"]:not(.sr-only):active ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):active ~ label,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):active ~ label,
.fuelux .radio input[type="checkbox"]:not(.sr-only):focus ~ label,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):focus ~ label,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):focus ~ label,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):focus ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only):focus ~ label,
.fuelux .checkbox input[type="radio"]:not(.sr-only):focus ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):focus ~ label,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):focus ~ label,
.fuelux .radio input[type="checkbox"]:not(.sr-only):active ~ label:hover,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):active ~ label:hover,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):active ~ label:hover,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):active ~ label:hover,
.fuelux .radio input[type="radio"]:not(.sr-only):active ~ label:hover,
.fuelux .checkbox input[type="radio"]:not(.sr-only):active ~ label:hover,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):active ~ label:hover,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):active ~ label:hover,
.fuelux .radio input[type="checkbox"]:not(.sr-only):focus ~ label:hover,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):focus ~ label:hover,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):focus ~ label:hover,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):focus ~ label:hover,
.fuelux .radio input[type="radio"]:not(.sr-only):focus ~ label:hover,
.fuelux .checkbox input[type="radio"]:not(.sr-only):focus ~ label:hover,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):focus ~ label:hover,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):focus ~ label:hover {
  color: #333333;
  cursor: pointer;
}
.fuelux .radio input[type="checkbox"]:not(.sr-only):active:hover ~ label,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):active:hover ~ label,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):active:hover ~ label,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):active:hover ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only):active:hover ~ label,
.fuelux .checkbox input[type="radio"]:not(.sr-only):active:hover ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):active:hover ~ label,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):active:hover ~ label,
.fuelux .radio input[type="checkbox"]:not(.sr-only):focus:hover ~ label,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):focus:hover ~ label,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):focus:hover ~ label,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):focus:hover ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only):focus:hover ~ label,
.fuelux .checkbox input[type="radio"]:not(.sr-only):focus:hover ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):focus:hover ~ label,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):focus:hover ~ label,
.fuelux .radio input[type="checkbox"]:not(.sr-only):focus:hover ~ label,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):focus:hover ~ label,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):focus:hover ~ label,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):focus:hover ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only):focus:hover ~ label,
.fuelux .checkbox input[type="radio"]:not(.sr-only):focus:hover ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):focus:hover ~ label,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):focus:hover ~ label,
.fuelux .radio input[type="checkbox"]:not(.sr-only):checked:hover ~ label,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):checked:hover ~ label,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):checked:hover ~ label,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):checked:hover ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only):checked:hover ~ label,
.fuelux .checkbox input[type="radio"]:not(.sr-only):checked:hover ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):checked:hover ~ label,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):checked:hover ~ label,
.fuelux .radio input[type="checkbox"]:not(.sr-only):active:hover ~ label:hover,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):active:hover ~ label:hover,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):active:hover ~ label:hover,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):active:hover ~ label:hover,
.fuelux .radio input[type="radio"]:not(.sr-only):active:hover ~ label:hover,
.fuelux .checkbox input[type="radio"]:not(.sr-only):active:hover ~ label:hover,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):active:hover ~ label:hover,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):active:hover ~ label:hover,
.fuelux .radio input[type="checkbox"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .radio input[type="radio"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .checkbox input[type="radio"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .radio input[type="checkbox"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .radio input[type="radio"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .checkbox input[type="radio"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .radio input[type="checkbox"]:not(.sr-only):checked:hover ~ label:hover,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):checked:hover ~ label:hover,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):checked:hover ~ label:hover,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):checked:hover ~ label:hover,
.fuelux .radio input[type="radio"]:not(.sr-only):checked:hover ~ label:hover,
.fuelux .checkbox input[type="radio"]:not(.sr-only):checked:hover ~ label:hover,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):checked:hover ~ label:hover,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):checked:hover ~ label:hover {
  color: #333333;
  cursor: pointer;
}
.fuelux .radio input[type="checkbox"]:not(.sr-only)[disabled="disabled"] ~ label,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only)[disabled="disabled"] ~ label,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only)[disabled="disabled"] ~ label,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only)[disabled="disabled"] ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only)[disabled="disabled"] ~ label,
.fuelux .checkbox input[type="radio"]:not(.sr-only)[disabled="disabled"] ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only)[disabled="disabled"] ~ label,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only)[disabled="disabled"] ~ label,
.fuelux .radio input[type="checkbox"]:not(.sr-only)[disabled] ~ label,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only)[disabled] ~ label,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only)[disabled] ~ label,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only)[disabled] ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only)[disabled] ~ label,
.fuelux .checkbox input[type="radio"]:not(.sr-only)[disabled] ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only)[disabled] ~ label,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only)[disabled] ~ label,
.fuelux .radio input[type="checkbox"]:not(.sr-only):disabled ~ label,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):disabled ~ label,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):disabled ~ label,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):disabled ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only):disabled ~ label,
.fuelux .checkbox input[type="radio"]:not(.sr-only):disabled ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):disabled ~ label,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):disabled ~ label,
.fuelux .radio input[type="checkbox"]:not(.sr-only)[disabled="disabled"] ~ label:before,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only)[disabled="disabled"] ~ label:before,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only)[disabled="disabled"] ~ label:before,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only)[disabled="disabled"] ~ label:before,
.fuelux .radio input[type="radio"]:not(.sr-only)[disabled="disabled"] ~ label:before,
.fuelux .checkbox input[type="radio"]:not(.sr-only)[disabled="disabled"] ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only)[disabled="disabled"] ~ label:before,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only)[disabled="disabled"] ~ label:before,
.fuelux .radio input[type="checkbox"]:not(.sr-only)[disabled] ~ label:before,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only)[disabled] ~ label:before,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only)[disabled] ~ label:before,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only)[disabled] ~ label:before,
.fuelux .radio input[type="radio"]:not(.sr-only)[disabled] ~ label:before,
.fuelux .checkbox input[type="radio"]:not(.sr-only)[disabled] ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only)[disabled] ~ label:before,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only)[disabled] ~ label:before,
.fuelux .radio input[type="checkbox"]:not(.sr-only):disabled ~ label:before,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):disabled ~ label:before,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):disabled ~ label:before,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):disabled ~ label:before,
.fuelux .radio input[type="radio"]:not(.sr-only):disabled ~ label:before,
.fuelux .checkbox input[type="radio"]:not(.sr-only):disabled ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):disabled ~ label:before,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):disabled ~ label:before {
  color: #333333;
  opacity: .65;
  cursor: not-allowed !important;
  outline: none;
  box-shadow: none;
}
.fuelux .radio input[type="checkbox"]:not(.sr-only):checked ~ label:before,
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):checked ~ label:before,
.fuelux .radio-inline input[type="checkbox"]:not(.sr-only):checked ~ label:before,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):checked ~ label:before,
.fuelux .radio input[type="radio"]:not(.sr-only):checked ~ label:before,
.fuelux .checkbox input[type="radio"]:not(.sr-only):checked ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):checked ~ label:before,
.fuelux .checkbox-inline input[type="radio"]:not(.sr-only):checked ~ label:before {
  background: #39b3d7;
  border-color: #39b3d7;
}
.fuelux .radio.highlight:before,
.fuelux .checkbox.highlight:before,
.fuelux .radio-inline.highlight:before,
.fuelux .checkbox-inline.highlight:before {
  left: 4px;
  top: 7px;
}
.fuelux .radio.highlight input[type="checkbox"]:not(.sr-only) ~ label,
.fuelux .checkbox.highlight input[type="checkbox"]:not(.sr-only) ~ label,
.fuelux .radio.highlight input[type="radio"]:not(.sr-only) ~ label,
.fuelux .checkbox.highlight input[type="radio"]:not(.sr-only) ~ label {
  left: -8px;
  position: relative;
}
.fuelux .radio-inline,
.fuelux .checkbox-inline {
  padding-left: 0;
}
.fuelux .radio-inline:before,
.fuelux .checkbox-inline:before {
  left: 0;
  top: 3px;
}
.fuelux .checkbox.multiline,
.fuelux .radio.multiline {
  float: left;
  margin-left: 15px;
}
.fuelux .checkbox.multiline ~ .control-label,
.fuelux .radio.multiline ~ .control-label {
  float: left;
  width: 80%;
  margin-left: 10px;
  text-align: left;
}
.fuelux .checkbox.multiline ~ .control-label > label,
.fuelux .radio.multiline ~ .control-label > label {
  font-weight: normal;
  cursor: pointer;
}
.fuelux .form-group.required .control-label:before {
  content: '*';
  color: #a94442;
  margin-right: 5px;
}
.fuelux .checkbox {
  /* for keyboard tabbing */
}
.fuelux .checkbox.highlight + .checkbox.highlight {
  margin-top: -5px;
}
.fuelux .checkbox.highlight label.checkbox-custom {
  padding: 4px 4px 4px 24px;
}
.fuelux .checkbox.highlight.checked label.checkbox-custom,
.fuelux .checkbox.highlight label.checked.checkbox-custom {
  background: #e6e6e6;
  border-radius: 4px;
}
.fuelux .checkbox input[type="checkbox"]:focus + .checkbox-label,
.fuelux .checkbox input[type="checkbox"]:hover + .checkbox-label,
.fuelux .checkbox .checkbox-label:hover {
  color: #999999;
}
.fuelux .checkbox input[type="checkbox"]:disabled:focus + .checkbox-label,
.fuelux .checkbox input[type="checkbox"]:disabled:hover + .checkbox-label,
.fuelux .checkbox.disabled label:hover {
  color: inherit;
}
.fuelux .form-horizontal .checkbox-inline {
  padding-top: 0;
}
.fuelux .input-group-addon.checkbox-custom {
  margin-bottom: 0;
  cursor: pointer;
  padding-left: 24px;
}
.fuelux .input-group-addon.checkbox-custom input[type=checkbox] {
  position: absolute;
  margin-left: -24px;
}
.fuelux .input-group-addon.checkbox-custom:before {
  left: 11px;
  top: 9px;
}
.fuelux .checkbox-custom {
  position: relative;
  /* for keyboard tabbing */
  /* for keyboard tabbing */
  /* for keyboard tabbing */
}
.fuelux .checkbox-custom:before {
  font-family: "Glyphicons Halflings" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  color: #FFF;
  content: " ";
  font-size: 9px;
  height: 14px;
  left: 0px;
  padding: 1px 0 0 1px;
  position: absolute;
  top: 3px;
  width: 14px;
}
.fuelux .checkbox-custom:active:before,
.fuelux .checkbox-custom:focus:before {
  color: #5B9DD9;
  cursor: pointer;
  box-shadow: inset 0px 0px 2px 1px #5B9DD9, 0px 0px 5px 0px #5B9DD9;
}
.fuelux .checkbox-custom.highlight:before {
  left: 4px;
  top: 6px;
}
.fuelux .checkbox-custom.checked:before {
  background: #39b3d7;
  border-color: #39b3d7;
  content: "\e013";
}
.fuelux .checkbox-custom.disabled {
  cursor: not-allowed;
  opacity: .65;
}
.fuelux .checkbox-custom.disabled:before {
  cursor: not-allowed;
  opacity: .65;
}
.fuelux .checkbox-custom.checkbox-inline:before {
  left: 0;
  top: 3px;
}
.fuelux .checkbox-custom.checkbox-inline.highlight {
  padding: 4px 4px 4px 24px;
  left: -4px;
}
.fuelux .checkbox-custom.checkbox-inline.highlight:before {
  left: 4px;
  top: 7px;
}
.fuelux .checkbox-custom.checkbox-inline.highlight.checked {
  background: #e6e6e6;
  border-radius: 4px;
}
.fuelux .checkbox-custom input[type="checkbox"]:focus + .checkbox-label {
  color: #999999;
}
.fuelux .checkbox-custom input[type="checkbox"] + .checkbox-label.truncate {
  display: inherit;
  line-height: 14px;
  margin-top: 3px;
  margin-bottom: -3px;
}
.fuelux label.checkbox-custom.checkbox-inline {
  padding-left: 20px;
}
.fuelux .checkbox input[type="checkbox"]:not(.sr-only) ~ label:before,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only) ~ label:before {
  border-radius: 4px;
  z-index: 1;
}
.fuelux .checkbox input[type="checkbox"]:not(.sr-only):checked ~ label:before,
.fuelux .checkbox-inline input[type="checkbox"]:not(.sr-only):checked ~ label:before {
  color: white;
  content: "\e013";
  line-height: 1em;
  padding-left: 1px;
  font-size: 8px;
  padding-top: 2px;
}
.fuelux .checkbox.hightlight + .checkbox-inline.highlight,
.fuelux .checkbox-inline.hightlight + .checkbox-inline.highlight {
  margin-left: -14px;
}
.fuelux .checkbox.hightlight + .checkbox-inline.highlight.checkbox-custom,
.fuelux .checkbox-inline.hightlight + .checkbox-inline.highlight.checkbox-custom {
  margin-left: 0px;
}
.fuelux .checkbox.highlight input[type="checkbox"]:not(.sr-only) ~ label {
  left: -4px;
}
.fuelux .radio {
  /* for keyboard tabbing */
}
.fuelux .radio.highlight {
  padding: 4px;
  margin: 0 0 5px 0;
}
.fuelux .radio.highlight + .radio.highlight {
  margin-top: -9px;
}
.fuelux .radio.highlight label.radio-custom {
  left: -8px;
  padding: 4px 4px 4px 24px;
  border-radius: 4px;
}
.fuelux .radio.highlight label.radio-custom:before {
  left: 5px;
  top: 7px;
}
.fuelux .radio.highlight label.radio-custom:after {
  left: 9px;
  top: 11px;
}
.fuelux .radio.highlight.checked label.radio-custom,
.fuelux .radio.highlight label.radio-custom.checked {
  background: #e6e6e6;
  border-radius: 4px;
}
.fuelux .radio input[type="radio"]:focus + .radio-label,
.fuelux .radio input[type="radio"]:hover + .radio-label,
.fuelux .radio .radio-label:hover {
  color: #999999;
}
.fuelux .form-horizontal .radio-inline {
  padding-top: 0;
}
.fuelux .input-group-addon.radio-custom.radio-inline:before {
  left: 11px;
  top: 9px;
}
.fuelux .input-group-addon.radio-custom.radio-inline:after {
  left: 15px;
  top: 13px;
}
.fuelux .radio-custom {
  position: relative;
  /* for keyboard tabbing */
  /* for keyboard tabbing */
}
.fuelux .radio-custom .highlight {
  padding: 4px;
  margin: 0 0 5px 0;
}
.fuelux .radio-custom .highlight.checked {
  background: #e6e6e6;
  border-radius: 4px;
}
.fuelux .radio-custom:after {
  background: transparent;
  border-radius: 6px;
  content: " ";
  height: 6px;
  left: 4px;
  position: absolute;
  top: 7px;
  width: 6px;
}
.fuelux .radio-custom:before {
  border: 1px solid #aaaaaa;
  border-radius: 14px;
  content: " ";
  height: 14px;
  left: 0px;
  position: absolute;
  top: 3px;
  width: 14px;
}
.fuelux .radio-custom.checked:after {
  background: #FFF;
}
.fuelux .radio-custom.checked:before {
  background: #39b3d7;
  border-color: #39b3d7;
}
.fuelux .radio-custom.disabled {
  cursor: not-allowed;
  opacity: .65;
}
.fuelux .radio-custom.disabled:after {
  cursor: not-allowed;
}
.fuelux .radio-custom.disabled:before {
  cursor: not-allowed;
  opacity: .65;
}
.fuelux .radio-custom.radio-inline:after {
  left: 4px;
  top: 7px;
}
.fuelux .radio-custom.radio-inline:before {
  left: 0;
  top: 3px;
}
.fuelux .radio-custom.radio-inline.highlight {
  left: -3px;
  padding: 4px 4px 4px 24px;
}
.fuelux .radio-custom.radio-inline.highlight:after {
  left: 8px;
  top: 11px;
}
.fuelux .radio-custom.radio-inline.highlight:before {
  left: 4px;
  top: 7px;
}
.fuelux .radio-custom.radio-inline.highlight.checked {
  background: #e6e6e6;
  border-radius: 4px;
}
.fuelux .radio-custom input[type="radio"]:focus + .radio-label {
  color: #999999;
}
.fuelux .radio-custom input[type="radio"] + .radio-label.truncate {
  display: inherit;
  line-height: 14px;
  margin-top: 3px;
  margin-bottom: -3px;
}
.fuelux label.radio-custom.radio-inline {
  padding-left: 20px;
}
.fuelux .radio input[type="radio"]:not(.sr-only),
.fuelux .radio-inline input[type="radio"]:not(.sr-only) {
  /* for keyboard tabbing */
  /* for keyboard tabbing */
}
.fuelux .radio input[type="radio"]:not(.sr-only) ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only) ~ label:before {
  border-radius: 8px;
  z-index: 1;
}
.fuelux .radio input[type="radio"]:not(.sr-only):checked ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):checked ~ label:before {
  background: #39b3d7;
  /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, #FFF 0%, #FFF 40%, #39b3d7 41%, #39b3d7 100%);
  /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #FFF), color-stop(40%, #FFF), color-stop(41%, #39b3d7), color-stop(100%, #39b3d7));
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover, #FFF 0%, #FFF 40%, #39b3d7 41%, #39b3d7 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(center, ellipse cover, #FFF 0%, #FFF 40%, #39b3d7 41%, #39b3d7 100%);
  /* Opera 12+ */
  background: -ms-radial-gradient(center, ellipse cover, #FFF 0%, #FFF 40%, #39b3d7 41%, #39b3d7 100%);
  /* IE10+ */
  background: radial-gradient(ellipse at center, #FFF 0%, #FFF 40%, #39b3d7 41%, #39b3d7 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF', endColorstr='#39b3d7', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}
.fuelux .radio input[type="radio"]:not(.sr-only):hover ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):hover ~ label:before,
.fuelux .radio input[type="radio"]:not(.sr-only):active ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):active ~ label:before,
.fuelux .radio input[type="radio"]:not(.sr-only):focus ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):focus ~ label:before {
  box-shadow: inset 0px 0px 2px 1px #5b9dd9, 0px 0px 5px 0px #5b9dd9;
}
.fuelux .radio input[type="radio"]:not(.sr-only):active:hover ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):active:hover ~ label:before,
.fuelux .radio input[type="radio"]:not(.sr-only):focus:hover ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):focus:hover ~ label:before,
.fuelux .radio input[type="radio"]:not(.sr-only):focus:hover ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):focus:hover ~ label:before,
.fuelux .radio input[type="radio"]:not(.sr-only):checked:hover ~ label:before,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):checked:hover ~ label:before {
  outline-color: none;
  outline-offset: 0;
  outline-style: none;
  outline-width: 0;
  cursor: default;
}
.fuelux .radio input[type="radio"]:not(.sr-only):active:hover ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):active:hover ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only):focus:hover ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):focus:hover ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only):focus:hover ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):focus:hover ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only):checked:hover ~ label,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):checked:hover ~ label,
.fuelux .radio input[type="radio"]:not(.sr-only):active:hover ~ label:hover,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):active:hover ~ label:hover,
.fuelux .radio input[type="radio"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .radio input[type="radio"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):focus:hover ~ label:hover,
.fuelux .radio input[type="radio"]:not(.sr-only):checked:hover ~ label:hover,
.fuelux .radio-inline input[type="radio"]:not(.sr-only):checked:hover ~ label:hover {
  color: #333333;
  cursor: default;
}
.fuelux .combobox.disabled .input-group-btn {
  cursor: not-allowed;
}
.fuelux .combobox input::-ms-clear {
  display: none;
}
.fuelux .combobox .dropdown-menu > li.selected > a {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
.fuelux .combobox .dropdown-menu > li > em {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
.fuelux .datepicker-calendar {
  padding: 16px 16px 0;
  min-height: 262px;
}
.fuelux .datepicker-calendar button {
  border: 0;
  padding: 0;
  background-color: transparent;
}
.fuelux .datepicker-calendar-days {
  height: 182px;
  margin: 10px 0 12px;
  width: 100%;
  /* for spacing */
}
.fuelux .datepicker-calendar-days thead {
  border-bottom: 1px solid #cccccc;
}
.fuelux .datepicker-calendar-days tbody:before {
  color: transparent;
  content: "\200C";
  display: block;
  line-height: 3px;
  visibility: hidden;
}
.fuelux .datepicker-calendar-days td,
.fuelux .datepicker-calendar-days th {
  font-size: 14px;
  height: 32px;
  text-align: center;
  vertical-align: middle;
  width: 14.29%;
}
.fuelux .datepicker-calendar-days td b,
.fuelux .datepicker-calendar-days td button {
  color: #333333;
  display: inline-block;
  font-weight: normal;
  height: 30px;
  text-decoration: none;
  width: 30px;
}
.fuelux .datepicker-calendar-days td b {
  line-height: 30px;
}
.fuelux .datepicker-calendar-days td span {
  display: block;
  border-radius: 4px;
}
.fuelux .datepicker-calendar-days td span:hover {
  background: #f5f5f5;
  text-decoration: none;
}
.fuelux .datepicker-calendar-days td.current-day span {
  border: 1px solid #333333;
}
.fuelux .datepicker-calendar-days td.last-month,
.fuelux .datepicker-calendar-days td.next-month {
  background: #d5d5d5;
}
.fuelux .datepicker-calendar-days td.last-month.first,
.fuelux .datepicker-calendar-days td.next-month.first {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-left: 1px;
}
.fuelux .datepicker-calendar-days td.last-month.last,
.fuelux .datepicker-calendar-days td.next-month.last {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-right: 1px;
}
.fuelux .datepicker-calendar-days td.past b,
.fuelux .datepicker-calendar-days td.past button {
  color: #999999;
}
.fuelux .datepicker-calendar-days td.restricted b,
.fuelux .datepicker-calendar-days td.restricted button {
  cursor: no-drop;
  position: relative;
}
.fuelux .datepicker-calendar-days td.restricted b:before,
.fuelux .datepicker-calendar-days td.restricted button:before {
  border-top: 1px solid #e9322d;
  bottom: 0;
  content: " ";
  display: block;
  left: 5px;
  position: absolute;
  right: 5px;
  top: 50%;
}
.fuelux .datepicker-calendar-days td.restricted b:hover,
.fuelux .datepicker-calendar-days td.restricted button:hover {
  background: none;
}
.fuelux .datepicker-calendar-days td.selected span {
  background: #eeeeee;
}
.fuelux .datepicker-calendar-days td.selected span:hover {
  background: #dddddd;
}
.fuelux .datepicker-calendar-days td.selected b,
.fuelux .datepicker-calendar-days td.selected button {
  color: #878787;
}
.fuelux .datepicker-calendar-days td.selected.current-day {
  box-shadow: 0 0 0 1px #FFF offset;
}
.fuelux .datepicker-calendar-days td.selected.current-day b,
.fuelux .datepicker-calendar-days td.selected.current-day button {
  color: #222222;
}
.fuelux .datepicker-calendar-days td.selected.current-day span:hover b,
.fuelux .datepicker-calendar-days td.selected.current-day span:hover button {
  border-color: #0f5f9f;
}
.fuelux .datepicker-calendar-days th {
  font-weight: bold;
  height: 22px;
  vertical-align: top;
}
.fuelux .datepicker-calendar-header {
  *zoom: 1;
}
.fuelux .datepicker-calendar-header:before,
.fuelux .datepicker-calendar-header:after {
  display: table;
  content: "";
  line-height: 0;
}
.fuelux .datepicker-calendar-header:after {
  clear: both;
}
.fuelux .datepicker-calendar-header button {
  border: 0;
  padding: 0;
  background-color: transparent;
}
.fuelux .datepicker-calendar-header .title {
  margin: 0 auto;
  text-align: center;
  display: block;
  width: 174px;
  color: #222222;
  font-size: 20px;
  line-height: 30px;
  text-decoration: underline;
  vertical-align: middle;
}
.fuelux .datepicker-calendar-header .title:hover {
  color: #0f5f9f;
  text-decoration: underline;
}
.fuelux .datepicker-calendar-header .title.disabled {
  cursor: default;
  pointer-events: none;
  text-decoration: none;
}
.fuelux .datepicker-calendar-header .title .month {
  display: inline;
  margin: 0;
  padding: 0;
}
.fuelux .datepicker-calendar-header .title .month span {
  display: none;
}
.fuelux .datepicker-calendar-header .title .month span.current {
  display: inline;
}
.fuelux .datepicker-calendar-header .next,
.fuelux .datepicker-calendar-header .prev {
  background: #666666;
  background-clip: padding-box;
  border-radius: 30px;
  cursor: pointer;
  float: left;
  height: 30px;
  text-align: center;
  width: 30px;
}
.fuelux .datepicker-calendar-header .next span.glyphicon,
.fuelux .datepicker-calendar-header .prev span.glyphicon {
  color: #FFF;
  font-size: 16px;
  line-height: 30px;
}
.fuelux .datepicker-calendar-header .next:hover,
.fuelux .datepicker-calendar-header .prev:hover {
  background: #0f5f9f;
}
.fuelux .datepicker-calendar-header .next {
  float: right;
}
.fuelux .datepicker-calendar-header .next span.glyphicon {
  line-height: 28px;
}
.fuelux .datepicker-calendar-footer {
  background: #e6e6e6;
  background-clip: padding-box;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #cccccc;
  height: 30px;
  margin: 0 -16px;
  padding: 4px 14px;
}
.fuelux .datepicker-calendar-footer .datepicker-today {
  color: #333333;
  font-size: 14px;
  text-decoration: underline;
}
.fuelux .datepicker-calendar-footer .datepicker-today:hover {
  color: #1b75bb;
  text-decoration: underline;
}
.fuelux .datepicker-calendar-footer .datepicker-today.disabled {
  color: #878787;
  cursor: default;
  pointer-events: none;
}
.fuelux .datepicker-calendar-footer .datepicker-today.disabled:hover {
  color: #878787;
}
.fuelux .datepicker-calendar-wrapper {
  border: 1px solid #757575;
  min-height: 20px;
  padding: 0;
  width: 300px;
}
.fuelux .datepicker-wheels {
  display: none;
}
.fuelux .datepicker-wheels ul button {
  border: 0;
  padding: 0;
  background-color: transparent;
}
.fuelux .datepicker-wheels-footer {
  background: #e6e6e6;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #cccccc;
  clear: both;
}
.fuelux .datepicker-wheels-footer .datepicker-wheels-back {
  display: block;
  color: #222222;
  float: left;
  line-height: 22px;
  border: 0;
  background-color: transparent;
}
.fuelux .datepicker-wheels-footer .datepicker-wheels-back:hover {
  color: #1b75bb;
}
.fuelux .datepicker-wheels-footer .datepicker-wheels-select {
  float: right;
  background-color: transparent;
  color: #5B9DD9;
}
.fuelux .datepicker-wheels-footer .datepicker-wheels-select:hover {
  color: #1b75bb;
}
.fuelux .datepicker-wheels-month,
.fuelux .datepicker-wheels-year {
  float: left;
  width: 50%;
}
.fuelux .datepicker-wheels-month ul,
.fuelux .datepicker-wheels-year ul {
  height: 217px;
  list-style-type: none;
  margin: 0;
  overflow: auto;
  padding: 0;
  text-align: center;
}
.fuelux .datepicker-wheels-month ul li,
.fuelux .datepicker-wheels-year ul li {
  margin: 4px 0;
}
.fuelux .datepicker-wheels-month ul li button,
.fuelux .datepicker-wheels-year ul li button {
  width: 100%;
  text-align: center;
  color: #454545;
  display: block;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}
.fuelux .datepicker-wheels-month ul li button:hover,
.fuelux .datepicker-wheels-year ul li button:hover {
  background: #d9edf7;
  text-decoration: none;
}
.fuelux .datepicker-wheels-month ul li.selected button,
.fuelux .datepicker-wheels-year ul li.selected button {
  background: #1b75bb;
  color: #FFF;
}
.fuelux .datepicker-wheels-month ul li.selected button:hover,
.fuelux .datepicker-wheels-year ul li.selected button:hover {
  background: #0f5f9f;
}
.fuelux .datepicker-wheels-month .header,
.fuelux .datepicker-wheels-year .header {
  background: #e6e6e6;
  background-clip: padding-box;
  border-bottom: 1px solid #cccccc;
  border-radius: 4px 0 0 0;
  color: #222222;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}
.fuelux .datepicker-wheels-month.full,
.fuelux .datepicker-wheels-year.full {
  border-left: 0;
  width: 100%;
}
.fuelux .datepicker-wheels-month.full .header,
.fuelux .datepicker-wheels-year.full .header {
  border-radius: 4px 4px 0 0;
}
.fuelux .datepicker-wheels-year {
  border-left: 1px solid #cccccc;
  float: right;
}
.fuelux .datepicker-wheels-year .header {
  border-radius: 0 4px 0 0;
}
.fuelux .datepicker input::-ms-clear {
  display: none;
}
.fuelux .infinitescroll {
  overflow-y: auto;
}
.fuelux .infinitescroll .infinitescroll-end {
  clear: both;
  font-style: italic;
  padding: 6px;
  text-align: center;
}
.fuelux .infinitescroll .infinitescroll-load {
  clear: both;
  padding: 6px;
}
.fuelux .infinitescroll .infinitescroll-load button {
  padding: 5px 12px;
  width: 100%;
}
.fuelux .infinitescroll .infinitescroll-load .loader {
  font-size: 32px;
  height: 32px;
  margin: 0 auto;
  width: 32px;
}
.fuelux .dropUp,
.fuelux .dropup {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.fuelux .loader {
  height: 64px;
  font-size: 64px;
  position: relative;
  width: 64px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4IiB2aWV3Qm94PSIwIDAgNjQgNjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGcgd2lkdGg9IjU0cHgiIGhlaWdodD0iNTRweCIgdmlld0JveD0iNSA1IDU0IDU0IiBpZD0ibG9hZGVyIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNS4wMDAwMDAsIDUuMDAwMDAwKSIgZmlsbD0iIzE1ODllZSI+PGcgaWQ9InNwaW5uZXItbWVkaXVtIj48ZyBpZD0iT3ZhbC0zNSI+PGNpcmNsZSBpZD0iVG9wIiBjeD0iMjciIGN5PSI2IiByPSI1Ij48L2NpcmNsZT48Y2lyY2xlIGlkPSJUb3BSaWdodCIgY3g9IjQ4IiBjeT0iMTciIHI9IjUiPjwvY2lyY2xlPjxjaXJjbGUgaWQ9IkJvdHRvbVJpZ2h0IiBjeD0iNDgiIGN5PSIzNiIgcj0iNSI+PC9jaXJjbGU+PGNpcmNsZSBpZD0iQm90dG9tIiBjeD0iMjciIGN5PSI0OCIgcj0iNSI+PC9jaXJjbGU+PGNpcmNsZSBpZD0iQm90dG9tTGVmdCIgY3g9IjYiIGN5PSIzNiIgcj0iNSI+PC9jaXJjbGU+PGNpcmNsZSBpZD0iVG9wTGVmdCIgY3g9IjYiIGN5PSIxNyIgcj0iNSI+PC9jaXJjbGU+PC9nPjwvZz48L2c+PGFuaW1hdGVNb3Rpb24geGxpbms6aHJlZj0iI1RvcCIgcGF0aD0iTSAwIDAgViAtNSBaIiBkdXI9Ii4yNXMiIGJlZ2luPSIwczsgVG9wTGVmdEFuaW0uZW5kIiBpZD0iVG9wQW5pbSIgLz48YW5pbWF0ZU1vdGlvbiB4bGluazpocmVmPSIjVG9wUmlnaHQiIHBhdGg9Ik0gMCAwIEwgNSAtNSBaIiBkdXI9Ii4yNXMiIGJlZ2luPSJUb3BBbmltLmVuZCIgaWQ9IlRvcFJpZ2h0QW5pbSIgLz48YW5pbWF0ZU1vdGlvbiB4bGluazpocmVmPSIjQm90dG9tUmlnaHQiIHBhdGg9Ik0gMCAwIEwgNSA1IFoiIGR1cj0iLjI1cyIgYmVnaW49IlRvcFJpZ2h0QW5pbS5lbmQiIGlkPSJCb3R0b21SaWdodEFuaW0iIC8+PGFuaW1hdGVNb3Rpb24geGxpbms6aHJlZj0iI0JvdHRvbSIgcGF0aD0iTSAwIDAgViA1IFoiIGR1cj0iLjI1cyIgYmVnaW49IkJvdHRvbVJpZ2h0QW5pbS5lbmQiIGlkPSJCb3R0b21BbmltIiAvPjxhbmltYXRlTW90aW9uIHhsaW5rOmhyZWY9IiNCb3R0b21MZWZ0IiBwYXRoPSJNIDAgMCBMIC01IDUgWiIgZHVyPSIuMjVzIiBiZWdpbj0iQm90dG9tQW5pbS5lbmQiIGlkPSJCb3R0b21MZWZ0QW5pbSIgLz48YW5pbWF0ZU1vdGlvbiB4bGluazpocmVmPSIjVG9wTGVmdCIgcGF0aD0iTSAwIDAgTCAtNSAtNSBaIiBkdXI9Ii4yNXMiIGJlZ2luPSJCb3R0b21MZWZ0QW5pbS5lbmQiIGlkPSJUb3BMZWZ0QW5pbSIgLz48L3N2Zz4=");
  background-size: contain;
}
.fuelux .pillbox {
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  cursor: text;
  padding: 3px;
}
.fuelux .pillbox[data-readonly] {
  border: 1px solid transparent;
}
.fuelux .pillbox[data-readonly].truncate .pillbox-input-wrap {
  display: inline-block;
}
.fuelux .pillbox[data-readonly].truncate .pillbox-input-wrap.truncated {
  display: none;
}
.fuelux .pillbox[data-readonly].truncate .pillbox-more {
  display: inline;
}
.fuelux .pillbox[data-readonly] .pill span.glyphicon-close {
  display: none;
}
.fuelux .pillbox[data-readonly] .pillbox-add-item {
  display: none;
}
.fuelux .pillbox[data-readonly] .pillbox-input-wrap {
  display: none;
}
.fuelux .pillbox[data-readonly] .pillbox-input-wrap button.dropdown-toggle {
  display: none;
}
.fuelux .pillbox.pills-editable .pill-group .pill span {
  cursor: text;
}
.fuelux .pillbox.pills-editable .pill-group .pill span.glyphicon-close {
  cursor: pointer;
}
.fuelux .pillbox > .pill-group {
  margin: 0;
  padding: 0;
  width: 100%;
}
.fuelux .pillbox > .pill-group > .pill {
  margin: 2px;
  cursor: default;
  float: left;
  word-break: break-all;
}
.fuelux .pillbox > .pill-group > .pill.pillbox-highlight {
  outline: 1px dotted #999999;
}
.fuelux .pillbox > .pill-group > .pill.truncated {
  display: none;
}
.fuelux .pillbox > .pill-group > .pill .pillbox-list-edit {
  border: none;
  color: #333333;
  font-weight: normal;
  border-radius: 2px;
}
.fuelux .pillbox > .pill-group > .pill .pillbox-list-edit:focus {
  outline: none;
  border: none;
}
.fuelux .pillbox > .pill-group > .pill .glyphicon-close {
  cursor: pointer;
  top: -1px;
  left: 2px;
}
.fuelux .pillbox > .pill-group > .pill .glyphicon-close:before {
  content: " \00d7";
}
.fuelux .pillbox > .pill-group > .pill .glyphicon-close:hover {
  opacity: .4;
}
.fuelux .pillbox .pillbox-input-wrap {
  position: relative;
  margin: 4px 0 3px 3px;
  float: left;
}
.fuelux .pillbox .pillbox-input-wrap.editing {
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin: 2px 0 3px 3px;
}
.fuelux .pillbox .pillbox-input-wrap.editing input.pillbox-add-item {
  border-radius: 4px;
  color: #333333;
  height: 32px;
}
.fuelux .pillbox .pillbox-add-item {
  border: none;
  box-shadow: none;
}
.fuelux .pillbox .pillbox-add-item:focus {
  outline: none;
  border: none;
}
.fuelux .pillbox .pillbox-more {
  cursor: pointer;
  display: none;
  line-height: 30px;
}
.fuelux .pillbox .suggest {
  max-height: 166px;
  overflow-y: auto;
}
.fuelux .pillbox .suggest > li {
  white-space: nowrap;
  cursor: pointer;
  padding: 3px 20px;
}
.fuelux .pillbox .suggest > li.pillbox-suggest-sel {
  background-color: #eeeeee;
}
.fuelux .pillbox .suggest > li:hover {
  background-color: #eeeeee;
}
.fuelux .pillbox input::-ms-clear {
  display: none;
}
.fuelux .placard {
  display: inline-block;
  position: relative;
}
.fuelux .placard[data-ellipsis="true"].showing div.placard-field,
.fuelux .placard[data-ellipsis="true"].showing input.placard-field {
  overflow: auto;
  text-overflow: clip;
  white-space: normal;
}
.fuelux .placard[data-ellipsis="true"] div.placard-field,
.fuelux .placard[data-ellipsis="true"] input.placard-field {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fuelux .placard[data-ellipsis="true"] div.placard-field::-ms-clear,
.fuelux .placard[data-ellipsis="true"] input.placard-field::-ms-clear {
  display: none;
}
.fuelux .placard[data-ellipsis="true"] div.placard-field[data-textarea] {
  overflow: auto;
  text-overflow: clip;
  white-space: normal;
}
.fuelux .placard[data-ellipsis="true"] textarea.placard-field[readonly] {
  overflow: hidden;
}
.fuelux .placard.showing .placard-footer,
.fuelux .placard.showing .placard-header,
.fuelux .placard.showing .placard-popup {
  display: block;
  z-index: 1;
}
.fuelux .placard.showing div.placard-field,
.fuelux .placard.showing input.placard-field,
.fuelux .placard.showing textarea.placard-field {
  background: #FFF;
  border: 1px solid #cccccc;
  box-shadow: none;
  position: relative;
  z-index: 1;
}
.fuelux .placard div.placard-field,
.fuelux .placard input.placard-field,
.fuelux .placard textarea.placard-field {
  resize: none;
}
.fuelux .placard div.placard-field[readonly],
.fuelux .placard input.placard-field[readonly],
.fuelux .placard textarea.placard-field[readonly] {
  background: #FFF;
  cursor: auto;
}
.fuelux .placard div.placard-field[readonly].glass,
.fuelux .placard input.placard-field[readonly].glass,
.fuelux .placard textarea.placard-field[readonly].glass {
  background: none;
}
.fuelux .placard div.placard-field[readonly].glass:hover,
.fuelux .placard input.placard-field[readonly].glass:hover,
.fuelux .placard textarea.placard-field[readonly].glass:hover {
  background: #d9edf7;
  cursor: pointer;
}
.fuelux .placard div.placard-field:focus,
.fuelux .placard input.placard-field:focus,
.fuelux .placard textarea.placard-field:focus {
  border: 1px solid #cccccc;
  box-shadow: none;
}
.fuelux .placard div.placard-field {
  width: 168px;
  overflow: auto;
}
.fuelux .placard div.placard-field[data-textarea] {
  height: 54px;
}
.fuelux .placard-cancel {
  font-size: 12px;
  margin-right: 4px;
  vertical-align: middle;
}
.fuelux .placard-footer,
.fuelux .placard-header {
  display: none;
  left: 0;
  line-height: 1;
  position: absolute;
  right: 0;
}
.fuelux .placard-footer {
  padding: 4px 0 8px 0;
  text-align: right;
  top: 100%;
}
.fuelux .placard-header {
  bottom: 100%;
  padding: 8px 0 4px 0;
}
.fuelux .placard-header h1,
.fuelux .placard-header h2,
.fuelux .placard-header h3,
.fuelux .placard-header h4,
.fuelux .placard-header h5,
.fuelux .placard-header h6 {
  margin: 0;
}
.fuelux .placard-popup {
  background: #d9edf7;
  background-clip: padding-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  bottom: -8px;
  box-shadow: 0 0 0 1px #FFF inset;
  display: none;
  left: -8px;
  position: absolute;
  right: -8px;
  top: -8px;
}
.fuelux .placard .glass {
  background: transparent;
  border: 1px solid #FFF;
  box-shadow: none;
}
.fuelux .placard .glass:hover {
  background: #d9edf7;
  border-color: #5B9DD9;
  cursor: pointer;
}
.fuelux .placard .glass:hover[disabled] {
  background: transparent;
  border-color: #FFF;
  cursor: not-allowed;
}
.fuelux .placard .glass:focus {
  background: #FFF;
  border-color: #5B9DD9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.75), 0 0 8px rgba(91, 157, 217, 0.6);
  cursor: auto;
}
.fuelux .placard .glass:focus[disabled] {
  background: transparent;
  border-color: #FFF;
  cursor: not-allowed;
}
.fuelux .placard .glass[disabled] {
  cursor: not-allowed;
}
.fuelux .repeater {
  border: 1px solid #dddddd;
  border-radius: 4px;
  /*
		 * reinforce spacing around control buttons
		 */
}
.fuelux .repeater-canvas.scrolling {
  bottom: 0;
  left: 0;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
}
.fuelux .repeater-header,
.fuelux .repeater-footer {
  background: #fafafa;
  min-height: 50px;
  padding: 8px;
  *zoom: 1;
}
.fuelux .repeater-header:before,
.fuelux .repeater-footer:before,
.fuelux .repeater-header:after,
.fuelux .repeater-footer:after {
  display: table;
  content: "";
  line-height: 0;
}
.fuelux .repeater-header:after,
.fuelux .repeater-footer:after {
  clear: both;
}
.fuelux .repeater-header-left,
.fuelux .repeater-footer-left {
  float: left;
}
.fuelux .repeater-header-right,
.fuelux .repeater-footer-right {
  float: right;
}
@media (max-width: 625px) {
  .fuelux .repeater-header-left,
  .fuelux .repeater-footer-left,
  .fuelux .repeater-header-right,
  .fuelux .repeater-footer-right {
    float: none;
    *zoom: 1;
  }
  .fuelux .repeater-header-left:before,
  .fuelux .repeater-footer-left:before,
  .fuelux .repeater-header-right:before,
  .fuelux .repeater-footer-right:before,
  .fuelux .repeater-header-left:after,
  .fuelux .repeater-footer-left:after,
  .fuelux .repeater-header-right:after,
  .fuelux .repeater-footer-right:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .fuelux .repeater-header-left:after,
  .fuelux .repeater-footer-left:after,
  .fuelux .repeater-header-right:after,
  .fuelux .repeater-footer-right:after {
    clear: both;
  }
  .fuelux .repeater-header-left,
  .fuelux .repeater-footer-left {
    margin-bottom: 8px;
  }
}
.fuelux .repeater-header {
  border-bottom: 1px solid #dddddd;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.fuelux .repeater-footer {
  border-top: 1px solid #dddddd;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.fuelux .repeater-loader {
  display: none;
  left: 50%;
  position: absolute;
  margin: -32px 0 0 -32px;
  top: 50%;
}
.fuelux .repeater-viewport {
  min-height: 80px;
  position: relative;
}
.fuelux .repeater-pagination label {
  font-weight: normal;
}
.fuelux .repeater-pagination .repeater-primaryPaging {
  display: none;
  vertical-align: middle;
  width: 116px;
}
.fuelux .repeater-pagination .repeater-primaryPaging.active {
  display: inline-block;
}
.fuelux .repeater-pagination .repeater-primaryPaging ul.dropdown-menu {
  min-width: 116px;
}
.fuelux .repeater-pagination .repeater-secondaryPaging {
  display: none;
  width: 82px;
}
.fuelux .repeater-pagination .repeater-secondaryPaging.active {
  display: inline-block;
}
.fuelux .repeater-search {
  float: left;
  width: 200px;
}
.fuelux .repeater-title {
  display: block;
  float: left;
  line-height: 34px;
  margin-right: 10px;
}
.fuelux .repeater-itemization .selectlist,
.fuelux .repeater-filters,
.fuelux .repeater-secondaryPaging,
.fuelux .repeater-primaryPaging,
.fuelux .repeater-next,
.fuelux .repeater-prev {
  margin: auto 0.3em;
}
.fuelux .repeater[data-viewtype="list"].disabled .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr.selectable:hover td,
.fuelux .repeater[data-viewtype="list"].disabled .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr.selectable.hovered td {
  background: #fff;
}
.fuelux .repeater[data-viewtype="list"].disabled .repeater-canvas .repeater-list .repeater-select-checkbox {
  cursor: not-allowed;
}
.fuelux .repeater[data-viewtype="list"].disabled .repeater-canvas .repeater-list .repeater-list-wrapper table thead tr th.sortable,
.fuelux .repeater[data-viewtype="list"].disabled .repeater-canvas .repeater-list .repeater-list-wrapper table thead tr th .repeater-list-heading.sortable {
  background: #fafafa;
  cursor: auto;
}
.fuelux .repeater[data-viewtype="list"].disabled .repeater-canvas .repeater-list .repeater-list-wrapper table tbody tr.selectable:hover.selected td,
.fuelux .repeater[data-viewtype="list"].disabled .repeater-canvas .repeater-list .repeater-list-wrapper table tbody tr.selectable.hovered.selected td {
  background: #eee;
}
.fuelux .repeater[data-viewtype="list"].disabled .repeater-canvas .repeater-list .repeater-list-wrapper table tbody tr.selectable:hover td,
.fuelux .repeater[data-viewtype="list"].disabled .repeater-canvas .repeater-list .repeater-list-wrapper table tbody tr.selectable.hovered td {
  background: #fff;
  cursor: auto;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.scrolling {
  overflow: visible;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.scrolling .repeater-list {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.scrolling .repeater-list-wrapper {
  height: 100%;
  overflow: auto;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list {
  position: relative;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table {
  margin-bottom: 0;
  width: 100%;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table tbody td {
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-top: none;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table tbody td:first-child {
  border-left: none;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table tbody td.sorted {
  background: #fafafa;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table tbody td.truncate {
  display: inherit;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table tbody tr:focus {
  outline: 1px dotted #dddddd;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table tbody tr.empty td {
  border-bottom: none;
  font-size: 14px;
  font-style: italic;
  padding: 20px;
  text-align: center;
  width: 100%;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table tbody tr.selectable:hover td,
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table tbody tr.selectable.hovered td {
  background: #f5f5f5;
  cursor: pointer;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table tbody tr.selectable .checkbox-custom:before {
  margin-top: -4px;
  top: 0;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table tbody tr.selected td {
  background: #eeeeee;
  color: #333333;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table tbody tr.selected td:first-child {
  padding-left: 30px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table thead > tr > th {
  background: #fafafa;
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid transparent;
  border-top: none;
  color: rgba(0, 0, 0, 0);
  line-height: 1.42857;
  padding: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table thead > tr > th:first-child {
  border-left: none;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table thead > tr > th:first-child .header-checkbox {
  width: 37px;
  padding-left: 12px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table thead > tr > th:first-child .header-checkbox .checkbox-inline:before {
  top: 0;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table thead > tr > th.sortable:hover,
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table thead > tr > th.sortable.sorted {
  background: #f5f5f5;
  cursor: pointer;
  background-color: #f2f2f2;
  background-image: -moz-linear-gradient(top, #fafafa, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fafafa), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #fafafa, #e6e6e6);
  background-image: -o-linear-gradient(top, #fafafa, #e6e6e6);
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffafafa', endColorstr='#ffe6e6e6', GradientType=0);
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table thead > tr > th.sorted span.glyphicon {
  display: block;
  visibility: hidden;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table thead > tr > th span.glyphicon {
  display: none;
  float: right;
  margin-top: 2px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list table thead > tr > th .actions-hidden {
  visibility: hidden;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list-check {
  display: inline-block;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: top;
  width: 0;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list-check span.glyphicon {
  left: -22px;
  position: absolute;
  top: 2px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list-heading {
  background: #fafafa;
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-top: none;
  color: #333333;
  line-height: 1.42857;
  margin-left: -9px;
  padding: 8px;
  position: absolute;
  top: 0;
  z-index: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list-heading.shifted {
  margin-left: -1px;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list-heading.shifted {
    margin-left: 0;
  }
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list-heading.sortable:hover,
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list-heading.sortable.sorted {
  background: #f5f5f5;
  cursor: pointer;
  background-color: #f2f2f2;
  background-image: -moz-linear-gradient(top, #fafafa, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fafafa), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #fafafa, #e6e6e6);
  background-image: -o-linear-gradient(top, #fafafa, #e6e6e6);
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffafafa', endColorstr='#ffe6e6e6', GradientType=0);
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list-heading.sortable.sorted span.glyphicon {
  display: block;
  visibility: visible;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas .repeater-list-heading span.glyphicon {
  display: none;
  float: right;
  margin-top: 2px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.frozen-enabled {
  overflow: auto;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.frozen-enabled .repeater-list-wrapper {
  overflow: visible;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.frozen-enabled .repeater-list .frozen-column-wrapper {
  position: absolute;
  z-index: 2;
  left: 0;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.frozen-enabled .repeater-list .frozen-thead-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.frozen-enabled .repeater-list .frozen-thead-wrapper table {
  border-right: 1px solid #dddddd;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.frozen-enabled .repeater-list table {
  table-layout: fixed;
  word-wrap: break-word;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.frozen-enabled .repeater-list table.table-frozen {
  border-right: 1px solid #dddddd;
  z-index: 2;
  background: #FFF;
  border-collapse: collapse;
  table-layout: fixed;
  float: left;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.frozen-enabled .repeater-list table.table-frozen td,
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.frozen-enabled .repeater-list table.table-frozen th {
  border-collapse: collapse;
  word-wrap: break-word;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.frozen-enabled .repeater-list table.table-frozen .repeater-frozen-heading {
  background: #fafafa;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.frozen-enabled .repeater-list table.table-frozen .repeater-frozen-heading.shifted {
  left: -1px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled {
  overflow: auto;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list-wrapper {
  overflow: visible;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper {
  position: absolute;
  z-index: 2;
  right: 0;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table {
  table-layout: fixed;
  word-wrap: break-word;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions {
  border-left: 1px solid #dddddd;
  z-index: 2;
  border-collapse: collapse;
  table-layout: fixed;
  float: right;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr {
  border-left: 1px solid #dddddd;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr.empty-heading {
  background: transparent;
  border-left: 1px solid transparent;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr.empty-heading th {
  background: transparent;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr td {
  background: #FFF;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr.selectable:hover td,
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr.selectable.hovered td {
  background: #f5f5f5;
  cursor: pointer;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr.selectable .checkbox-custom:before {
  margin-top: -4px;
  top: 0;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr.selected td {
  background: #eeeeee;
  color: #333333;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr.selected td:first-child {
  padding-left: 30px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr td,
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr th {
  border-collapse: collapse;
  word-wrap: break-word;
  padding-bottom: 5px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr td .btn-group,
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr th .btn-group {
  width: 100%;
  text-align: center;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr td .btn-group .btn,
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr th .btn-group .btn {
  float: none;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr.selected td:first-child {
  padding-left: 8px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr th {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 7px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr th .repeater-list-heading {
  padding: 8px 0 7px;
  border-left: 1px solid #fafafa;
  margin-left: -9px;
  width: 100%;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr th .repeater-list-heading .glyphicon {
  display: inline-block;
  float: none;
  margin-top: 0;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-column-wrapper table.table-actions .caret {
  margin-left: 0;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list .actions-thead-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list table {
  table-layout: fixed;
  word-wrap: break-word;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list table thead tr th:last-child .repeater-list-heading {
  border-left: 1px solid transparent;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list.ie-9 .actions-column-wrapper table.table-actions {
  background-color: #FFF;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list.ie-9 .actions-column-wrapper table.table-actions tr {
  background-color: transparent;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list.ie-9 .actions-column-wrapper table.table-actions tr.empty-heading {
  border-left: 1px solid #fafafa;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list.ie-9 .actions-column-wrapper table.table-actions tr th {
  padding-bottom: 8px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.actions-enabled .repeater-list.ie-9 .actions-column-wrapper table.table-actions tr td {
  padding-bottom: 6px;
  line-height: 1.39;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.multi-select-enabled .repeater-list thead tr:first-child .header-checkbox {
  width: 37px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.multi-select-enabled .repeater-list thead tr:first-child .header-checkbox .checkbox {
  margin: 0 4px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.multi-select-enabled .repeater-list tbody tr .body-checkbox {
  left: 4px;
  top: -3px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.multi-select-enabled .repeater-list tr.selected td:first-child {
  padding-left: 8px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-canvas.multi-select-enabled.actions-enabled .repeater-list .actions-column-wrapper table.table-actions tr th .repeater-list-heading {
  border-left: 1px solid #dddddd;
}
.fuelux .repeater[data-viewtype="list"] .repeater-loader {
  margin-top: -12px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-loader.noHeader {
  margin-top: -32px;
}
.fuelux .repeater[data-viewtype="list"] .repeater-viewport {
  overflow: hidden;
}
.fuelux .repeater[data-viewtype="list"] .actions-wrapper {
  z-index: 10;
  text-align: right;
}
.fuelux .repeater.disabled .repeater-thumbnail.selectable:hover {
  background: #fff;
  cursor: auto;
}
.fuelux .repeater.disabled .repeater-thumbnail.selectable.selected:hover {
  background: #eeeeee;
}
.fuelux .repeater-thumbnail {
  border: 1px solid #dddddd;
  color: #666666;
  float: left;
  font-size: 14px;
  min-height: 110px;
  min-width: 100px;
  margin: 8px 7px;
  text-align: center;
}
.fuelux .repeater-thumbnail img {
  display: block;
  margin-top: 10px;
  margin-bottom: 3px;
}
.fuelux .repeater-thumbnail-cont {
  height: 100%;
  overflow-y: auto;
  padding: 6px;
  width: 100%;
}
.fuelux .repeater-thumbnail-cont.align-center,
.fuelux .repeater-thumbnail-cont.align-justify,
.fuelux .repeater-thumbnail-cont.align-left,
.fuelux .repeater-thumbnail-cont.align-right {
  position: relative;
  font-size: 0.1px;
}
.fuelux .repeater-thumbnail-cont.align-center:after,
.fuelux .repeater-thumbnail-cont.align-justify:after,
.fuelux .repeater-thumbnail-cont.align-left:after,
.fuelux .repeater-thumbnail-cont.align-right:after {
  display: inline-block;
  width: 100%;
  content: '';
}
.fuelux .repeater-thumbnail-cont.align-center .infinitescroll-end,
.fuelux .repeater-thumbnail-cont.align-justify .infinitescroll-end,
.fuelux .repeater-thumbnail-cont.align-left .infinitescroll-end,
.fuelux .repeater-thumbnail-cont.align-right .infinitescroll-end,
.fuelux .repeater-thumbnail-cont.align-center .infinitescroll-load,
.fuelux .repeater-thumbnail-cont.align-justify .infinitescroll-load,
.fuelux .repeater-thumbnail-cont.align-left .infinitescroll-load,
.fuelux .repeater-thumbnail-cont.align-right .infinitescroll-load {
  display: inline-block;
  width: 100%;
}
.fuelux .repeater-thumbnail-cont.align-center .repeater-thumbnail,
.fuelux .repeater-thumbnail-cont.align-justify .repeater-thumbnail,
.fuelux .repeater-thumbnail-cont.align-left .repeater-thumbnail,
.fuelux .repeater-thumbnail-cont.align-right .repeater-thumbnail {
  display: inline-block;
  float: none;
  font-size: 14px;
}
.fuelux .repeater-thumbnail-cont.align-center .repeater-thumbnail:after,
.fuelux .repeater-thumbnail-cont.align-justify .repeater-thumbnail:after,
.fuelux .repeater-thumbnail-cont.align-left .repeater-thumbnail:after,
.fuelux .repeater-thumbnail-cont.align-right .repeater-thumbnail:after {
  content: ' ';
}
.fuelux .repeater-thumbnail-cont.align-center {
  text-align: center;
}
.fuelux .repeater-thumbnail-cont.align-justify {
  text-align: justify;
}
.fuelux .repeater-thumbnail-cont.align-left {
  text-align: left;
}
.fuelux .repeater-thumbnail-cont.align-right {
  text-align: right;
}
.fuelux .repeater-thumbnail-cont div.empty {
  font-size: 14px;
  font-style: italic;
  padding: 14px 10px 20px;
  text-align: center;
}
.fuelux .repeater-thumbnail-cont .selectable:hover {
  background: #f5f5f5;
  cursor: pointer;
}
.fuelux .repeater-thumbnail-cont .selectable.selected {
  background: #eeeeee;
}
.fuelux .repeater-thumbnail-cont .selectable.selected:hover {
  background: #dddddd;
}
.fuelux .spinbox {
  display: inline-block;
  position: relative;
}
.fuelux .spinbox.digits-3 {
  width: 60px;
}
.fuelux .spinbox.disabled .spinbox-buttons {
  cursor: not-allowed;
}
.fuelux .spinbox .spinbox-input {
  float: left;
  padding-bottom: 3px;
  /* Magic number */
  padding: 0px 25px 0px 7px;
}
.fuelux .spinbox .spinbox-input::-ms-clear {
  display: none;
}
.fuelux .spinbox .btn {
  position: relative;
  width: 20px;
  height: 13px;
  padding-top: 0;
  padding-right: 9px;
  padding-left: 9px;
  overflow-y: hidden;
}
.fuelux .spinbox .btn.disabled {
  cursor: not-allowed;
}
.fuelux .spinbox .spinbox-buttons {
  position: absolute;
  height: 28px;
  width: 20px;
  right: 2px;
}
.fuelux .spinbox .spinbox-up {
  padding: 0 0 14px 1px;
  top: 2px;
}
.fuelux .spinbox .spinbox-up .glyphicon-chevron-up {
  position: relative;
  top: 0px;
}
.fuelux .spinbox .btn-group > .btn.spinbox-up {
  border-top-right-radius: 4px;
}
.fuelux .spinbox .spinbox-down {
  padding: 0 0 8px 1px;
  top: 2px;
  height: 15px;
  border-bottom-left-radius: 4px;
}
.fuelux .spinbox .spinbox-down .glyphicon-chevron-down {
  position: relative;
  left: 0;
  top: 0;
}
.fuelux .spinbox input::-ms-clear {
  display: none;
}
.fuelux .scheduler {
  /* -------------------------------
		START DATE/TIME
	------------------------------- */
  /* -------------------------------
		TIMEZONE
	------------------------------- */
  /* -------------------------------
		REPEAT PANELS
	------------------------------- */
  /* -------------------------------
	END
	------------------------------- */
}
.fuelux .scheduler .row {
  margin-bottom: 10px;
}
.fuelux .scheduler .row.no-margin {
  margin-bottom: 0px;
}
.fuelux .scheduler .control-label {
  min-width: 7em;
}
.fuelux .scheduler .inline-form-text {
  float: left;
  line-height: 27px;
  margin-top: 4px;
}
.fuelux .scheduler .form-group {
  margin-bottom: 0px;
}
.fuelux .scheduler .start-datetime .form-group {
  margin-left: 0;
}
.fuelux .scheduler .start-datetime .combobox {
  max-width: 9em;
}
.fuelux .scheduler .start-datetime .combobox .dropdown-menu {
  max-height: 200px;
  overflow: auto;
}
.fuelux .scheduler .start-datetime .dropdown {
  float: left;
  margin: 0 10px 0 0;
}
.fuelux .scheduler .timezone-container .input-group {
  max-width: 20em;
}
.fuelux .scheduler .timezone-container .dropdown-label {
  height: 18px;
  white-space: nowrap;
  max-width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fuelux .scheduler .timezone-container .dropdown-menu {
  max-height: 200px;
  overflow: auto;
}
.fuelux .scheduler .repeat-panel {
  margin-left: 0;
}
.fuelux .scheduler .repeat-panel label {
  font-weight: normal;
}
.fuelux .scheduler .repeat-panel .radio {
  margin-right: 10px;
}
.fuelux .scheduler .repeat-panel .form-group {
  margin-left: 0;
}
.fuelux .scheduler .repeat-interval {
  margin-left: 0;
  *zoom: 1;
}
.fuelux .scheduler .repeat-interval:before,
.fuelux .scheduler .repeat-interval:after {
  display: table;
  content: "";
  line-height: 0;
}
.fuelux .scheduler .repeat-interval:after {
  clear: both;
}
.fuelux .scheduler .repeat-interval .form-group {
  margin-left: 0;
}
.fuelux .scheduler .repeat-interval .dropdown-menu {
  max-height: 200px;
  overflow: auto;
}
.fuelux .scheduler .repeat-interval .repeat-every-panel {
  float: left;
}
.fuelux .scheduler .repeat-interval .repeat-every-panel .repeat-every-pretext {
  padding: 0 10px;
}
.fuelux .scheduler .repeat-interval .repeat-every-panel .spinbox {
  float: left;
  margin-right: 10px;
}
.fuelux .scheduler .repeat-interval .repeat-every-panel .spinbox input {
  margin-bottom: 0;
}
.fuelux .scheduler .repeat-monthly .repeat-monthly-date {
  margin-top: 10px;
  *zoom: 1;
}
.fuelux .scheduler .repeat-monthly .repeat-monthly-date:before,
.fuelux .scheduler .repeat-monthly .repeat-monthly-date:after {
  display: table;
  content: "";
  line-height: 0;
}
.fuelux .scheduler .repeat-monthly .repeat-monthly-date:after {
  clear: both;
}
.fuelux .scheduler .repeat-monthly .repeat-monthly-date .selectlist {
  margin-left: 5px;
}
.fuelux .scheduler .repeat-monthly .repeat-monthly-day {
  margin-top: 10px;
  *zoom: 1;
}
.fuelux .scheduler .repeat-monthly .repeat-monthly-day:before,
.fuelux .scheduler .repeat-monthly .repeat-monthly-day:after {
  display: table;
  content: "";
  line-height: 0;
}
.fuelux .scheduler .repeat-monthly .repeat-monthly-day:after {
  clear: both;
}
.fuelux .scheduler .repeat-monthly .month-days {
  margin-left: 10px;
}
.fuelux .scheduler .repeat-yearly .repeat-yearly-date {
  margin-top: 10px;
  *zoom: 1;
}
.fuelux .scheduler .repeat-yearly .repeat-yearly-date:before,
.fuelux .scheduler .repeat-yearly .repeat-yearly-date:after {
  display: table;
  content: "";
  line-height: 0;
}
.fuelux .scheduler .repeat-yearly .repeat-yearly-date:after {
  clear: both;
}
.fuelux .scheduler .repeat-yearly .repeat-yearly-date .year-month-day {
  margin-left: 10px;
}
.fuelux .scheduler .repeat-yearly .year-month-days {
  margin-left: 10px;
}
.fuelux .scheduler .repeat-yearly .year-month {
  margin-left: 10px;
}
.fuelux .scheduler .repeat-yearly .repeat-yearly-day {
  margin-top: 10px;
  *zoom: 1;
}
.fuelux .scheduler .repeat-yearly .repeat-yearly-day:before,
.fuelux .scheduler .repeat-yearly .repeat-yearly-day:after {
  display: table;
  content: "";
  line-height: 0;
}
.fuelux .scheduler .repeat-yearly .repeat-yearly-day:after {
  clear: both;
}
.fuelux .scheduler .repeat-yearly .repeat-yearly-day .repeat-yearly-day-text {
  margin-left: 10px;
}
.fuelux .scheduler .repeat-weekly.repeat-days-of-the-week {
  margin-top: 10px;
  *zoom: 1;
}
.fuelux .scheduler .repeat-weekly.repeat-days-of-the-week:before,
.fuelux .scheduler .repeat-weekly.repeat-days-of-the-week:after {
  display: table;
  content: "";
  line-height: 0;
}
.fuelux .scheduler .repeat-weekly.repeat-days-of-the-week:after {
  clear: both;
}
.fuelux .scheduler .repeat-weekly button:not(.active) {
  background-color: #FFF;
}
.fuelux .scheduler .repeat-weekly .btn-group.disabled {
  position: relative;
  opacity: 0.65;
}
.fuelux .scheduler .repeat-weekly .btn-group.disabled:before {
  background: transparent;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}
.fuelux .scheduler .selectlist {
  float: left;
}
.fuelux .scheduler label.radio {
  float: left;
  line-height: 27px;
}
.fuelux .scheduler label.radio input {
  margin-top: 8px;
}
.fuelux .scheduler .repeat-end .end-after {
  float: left;
  display: inline-block;
  margin-right: 10px;
}
.fuelux .scheduler .repeat-end .form-group {
  margin-left: 0;
}
.fuelux .scheduler .repeat-end .end-option-panel {
  padding-left: 10px;
}
.fuelux .scheduler .repeat-end .selectlist {
  min-width: 100%;
}
.fuelux .scheduler .repeat-end .selectlist button,
.fuelux .scheduler .repeat-end .selectlist ul {
  min-width: 100%;
}
.fuelux .scheduler input::-ms-clear {
  display: none;
}
.fuelux .search.disabled .input-group-btn {
  cursor: not-allowed;
}
.fuelux .search input::-ms-clear {
  display: none;
}
.fuelux .selectlist.disabled {
  cursor: not-allowed;
}
.fuelux .selectlist .selected-label {
  overflow: hidden;
  min-width: 2em;
}
.fuelux .selectlist .dropdown-menu {
  min-width: inherit;
  overflow-y: auto;
}
.fuelux .selectlist .btn.dropdown-toggle {
  text-align: left;
  padding-right: 28px;
}
.fuelux .selectlist .btn.dropdown-toggle .caret {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
}
.fuelux .button-sizer,
.fuelux .selectlist-sizer {
  display: inline-block;
  position: absolute;
  visibility: hidden;
  top: 0;
  float: left;
}
.fuelux .button-sizer .selectlist,
.fuelux .selectlist-sizer .selectlist {
  display: block !important;
}
.fuelux .button-sizer .dropdown-menu,
.fuelux .selectlist-sizer .dropdown-menu {
  display: block;
  min-width: inherit;
}
.fuelux .button-sizer .selected-label,
.fuelux .selectlist-sizer .selected-label {
  width: auto !important;
}
.fuelux .picker {
  display: inline-block;
  position: relative;
}
.fuelux .picker[data-ellipsis="true"].showing input.picker-field {
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
}
.fuelux .picker[data-ellipsis="true"] input.picker-field {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fuelux .picker[data-ellipsis="true"] input.picker-field::-ms-clear {
  display: none;
}
.fuelux .picker[data-ellipsis="true"] textarea.picker-field[readonly] {
  overflow: hidden;
}
.fuelux .picker.showing .picker-footer,
.fuelux .picker.showing .picker-header,
.fuelux .picker.showing .picker-popup {
  display: block;
  z-index: 1;
}
.fuelux .picker.showing input.picker-field,
.fuelux .picker.showing textarea.picker-field {
  background: #FFF;
  border: 1px solid #cccccc;
  box-shadow: none;
  position: relative;
  z-index: 1;
}
.fuelux .picker input.picker-field,
.fuelux .picker textarea.picker-field {
  resize: none;
}
.fuelux .picker input.picker-field[readonly],
.fuelux .picker textarea.picker-field[readonly] {
  background: #FFF;
  cursor: auto;
}
.fuelux .picker input.picker-field[readonly].glass,
.fuelux .picker textarea.picker-field[readonly].glass {
  background: none;
}
.fuelux .picker input.picker-field[readonly].glass:hover,
.fuelux .picker textarea.picker-field[readonly].glass:hover {
  background: #d9edf7;
  cursor: pointer;
}
.fuelux .picker input.picker-field:focus,
.fuelux .picker textarea.picker-field:focus {
  border: 1px solid #cccccc;
  box-shadow: none;
}
.fuelux .picker-cancel {
  font-size: 12px;
  margin-right: 4px;
  vertical-align: middle;
}
.fuelux .picker-footer,
.fuelux .picker-header {
  display: none;
  left: 0;
  line-height: 1;
  right: 0;
}
.fuelux .picker-footer {
  padding: 8px 10px 8px 0;
  text-align: right;
  bottom: 0;
  position: absolute;
}
.fuelux .picker-header {
  height: 31px;
  padding: 8px 0 0px 10px;
}
.fuelux .picker-header h1,
.fuelux .picker-header h2,
.fuelux .picker-header h3,
.fuelux .picker-header h4,
.fuelux .picker-header h5,
.fuelux .picker-header h6 {
  margin: 0;
}
.fuelux .picker-popup {
  background: #d9edf7;
  background-clip: padding-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #FFF inset;
  display: none;
  position: absolute;
  padding-left: 6px;
  padding-right: 6px;
  height: 234px;
  width: 350px;
  margin: 4px 0;
}
.fuelux .picker .picker-body.well {
  background-color: #fff;
  overflow: scroll;
  padding: 0;
  height: 165px;
}
.fuelux .picker .tree {
  border: none;
}
.fuelux .picker .glass {
  background: transparent;
  border: 1px solid #FFF;
  box-shadow: none;
}
.fuelux .picker .glass:hover {
  background: #d9edf7;
  border-color: #5B9DD9;
  cursor: pointer;
}
.fuelux .picker .glass:hover[disabled] {
  background: transparent;
  border-color: #FFF;
  cursor: not-allowed;
}
.fuelux .picker .glass:focus {
  background: #FFF;
  border-color: #5B9DD9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.75), 0 0 8px rgba(91, 157, 217, 0.6);
  cursor: auto;
}
.fuelux .picker .glass:focus[disabled] {
  background: transparent;
  border-color: #FFF;
  cursor: not-allowed;
}
.fuelux .picker .glass[disabled] {
  cursor: not-allowed;
}
.fuelux .tree {
  border: 1px solid #999999;
  border-radius: 4px 4px 4px 4px;
  padding: 10px 15px 0 15px;
  overflow-x: auto;
  overflow-y: auto;
  position: relative;
  list-style: none;
}
.fuelux .tree *:focus {
  outline: none;
}
.fuelux .tree :focus > .tree-branch-header .tree-label,
.fuelux .tree :focus > button .tree-label {
  outline: 0;
  text-decoration: underline;
}
.fuelux .tree button:focus {
  color: #999999;
}
.fuelux .tree ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}
.fuelux .tree li {
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
.fuelux .tree .tree-loader {
  margin-left: 65px;
}
.fuelux .tree > .tree-loader {
  margin-left: 50px;
}
.fuelux .tree .tree-open > .tree-branch-header .glyphicon-play {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  position: relative;
}
.fuelux .tree [data-children=false] > .tree-branch-header .icon-caret:before {
  content: '\00a0';
}
.fuelux .tree .tree-branch .tree-branch-header {
  position: relative;
  border-radius: 6px;
  white-space: nowrap;
}
.fuelux .tree .tree-branch .tree-branch-header .tree-branch-name:hover {
  color: #999999;
}
.fuelux .tree .tree-branch .tree-branch-header .glyphicon-play {
  font-size: 10px;
  padding-right: 5px;
  padding-left: 7px;
}
.fuelux .tree .tree-branch .tree-branch-header .glyphicon-play:before {
  position: relative;
  top: -2px;
}
.fuelux .tree .tree-branch .tree-branch-header .tree-branch-name {
  white-space: nowrap;
  border-radius: 6px;
  background-color: transparent;
  border: 0;
}
.fuelux .tree .tree-branch .tree-branch-header label {
  font-weight: normal;
  padding-left: 3px;
  margin-bottom: 0;
  cursor: pointer;
}
.fuelux .tree .tree-branch .tree-branch-children {
  margin-left: 14px;
}
.fuelux .tree .tree-branch[haschildren='false'] .icon-caret,
.fuelux .tree .tree-branch[data-has-children='false'] .icon-caret {
  visibility: hidden;
}
.fuelux .tree .tree-item,
.fuelux .tree .tree-overflow {
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  border-radius: 6px;
  margin-left: 26px;
}
.fuelux .tree .tree-item .tree-item-name,
.fuelux .tree .tree-overflow .tree-item-name,
.fuelux .tree .tree-item .tree-overflow-name,
.fuelux .tree .tree-overflow .tree-overflow-name {
  white-space: nowrap;
  border-radius: 6px;
  background-color: transparent;
  border: 0;
}
.fuelux .tree .tree-item .tree-overflow-name .tree-label,
.fuelux .tree .tree-overflow .tree-overflow-name .tree-label {
  margin-left: 15px;
  color: #0088cc;
}
.fuelux .tree .tree-item .tree-item-name:hover,
.fuelux .tree .tree-overflow .tree-item-name:hover,
.fuelux .tree .tree-item .tree-overflow-name:hover,
.fuelux .tree .tree-overflow .tree-overflow-name:hover {
  color: #999999;
}
.fuelux .tree .tree-item.tree-selected .tree-item-name,
.fuelux .tree .tree-overflow.tree-selected .tree-item-name {
  background-color: #eeeeee;
  color: #333333;
}
.fuelux .tree .tree-item label,
.fuelux .tree .tree-overflow label {
  font-weight: normal;
  padding-left: 7px;
  margin-bottom: 0;
  cursor: pointer;
}
.fuelux .tree .icon-caret:hover + .tree-branch-name {
  color: #999999;
}
.fuelux .tree.tree-folder-select .tree-branch .icon-caret {
  background-color: transparent;
  border: 0;
}
.fuelux .tree.tree-folder-select .tree-branch .icon-caret:hover {
  cursor: pointer;
  color: #999999;
}
.fuelux .tree.tree-folder-select .tree-branch > .tree-branch-name {
  padding: 1px 5px 0 5px;
  border-radius: 6px;
}
.fuelux .tree.tree-folder-select .tree-branch > .tree-branch-name.tree-selected {
  background-color: #eeeeee;
}
.fuelux .tree.tree-folder-select .tree-branch.tree-selected > .tree-branch-header .tree-branch-name {
  background-color: #eeeeee;
}
.fuelux .tree.tree-folder-select .tree-branch.tree-selected > .tree-branch-header .tree-branch-name:hover {
  background-color: #eeeeee;
}
.fuelux .truncate .tree .tree-branch-header .tree-branch-name,
.fuelux .truncate .tree .tree-item .tree-item-name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fuelux .wizard {
  *zoom: 1;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  background-color: #fafafa;
  position: relative;
  min-height: 48px;
}
.fuelux .wizard:before,
.fuelux .wizard:after {
  display: table;
  content: "";
  line-height: 0;
}
.fuelux .wizard:after {
  clear: both;
}
.fuelux .wizard.no-steps-container {
  overflow: hidden;
}
.fuelux .wizard .steps-container {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}
.fuelux .wizard > ul.steps,
.fuelux .wizard > .steps-container > ul.steps {
  list-style: none outside none;
  padding: 0;
  margin: 0;
  width: 999999px;
}
.fuelux .wizard > ul.steps.previous-disabled li.complete,
.fuelux .wizard > .steps-container > ul.steps.previous-disabled li.complete {
  cursor: default;
}
.fuelux .wizard > ul.steps.previous-disabled li.complete:hover,
.fuelux .wizard > .steps-container > ul.steps.previous-disabled li.complete:hover {
  background: #f5f5f5;
  color: #468847;
  cursor: default;
}
.fuelux .wizard > ul.steps.previous-disabled li.complete:hover .chevron:before,
.fuelux .wizard > .steps-container > ul.steps.previous-disabled li.complete:hover .chevron:before {
  border-left-color: #f5f5f5;
}
.fuelux .wizard > ul.steps li,
.fuelux .wizard > .steps-container > ul.steps li {
  float: left;
  margin: 0;
  padding: 0 20px 0 30px;
  height: 46px;
  line-height: 46px;
  position: relative;
  background: #eeeeee;
  color: #999999;
  font-size: 16px;
  cursor: not-allowed;
}
.fuelux .wizard > ul.steps li > .has-secondary-text,
.fuelux .wizard > .steps-container > ul.steps li > .has-secondary-text {
  display: inline;
}
.fuelux .wizard > ul.steps li > .has-secondary-text > h4,
.fuelux .wizard > .steps-container > ul.steps li > .has-secondary-text > h4 {
  display: inline;
  font-size: 16px;
  font-weight: normal;
}
.fuelux .wizard > ul.steps li > .has-secondary-text > small,
.fuelux .wizard > .steps-container > ul.steps li > .has-secondary-text > small {
  display: none;
}
.fuelux .wizard > ul.steps li .chevron,
.fuelux .wizard > .steps-container > ul.steps li .chevron {
  border: 24px solid transparent;
  border-left: 14px solid #d4d4d4;
  border-right: 0;
  display: block;
  position: absolute;
  right: -14px;
  top: 0;
  z-index: 1;
}
.fuelux .wizard > ul.steps li .chevron:before,
.fuelux .wizard > .steps-container > ul.steps li .chevron:before {
  border: 24px solid transparent;
  border-left: 14px solid #eeeeee;
  border-right: 0;
  content: "";
  display: block;
  position: absolute;
  right: 1px;
  top: -24px;
}
.fuelux .wizard > ul.steps li.complete,
.fuelux .wizard > .steps-container > ul.steps li.complete {
  background: #f5f5f5;
  color: #468847;
}
.fuelux .wizard > ul.steps li.complete:hover,
.fuelux .wizard > .steps-container > ul.steps li.complete:hover {
  background: #e2eaee;
  cursor: pointer;
}
.fuelux .wizard > ul.steps li.complete:hover .chevron:before,
.fuelux .wizard > .steps-container > ul.steps li.complete:hover .chevron:before {
  border-left: 14px solid #e2eaee;
}
.fuelux .wizard > ul.steps li.complete .chevron:before,
.fuelux .wizard > .steps-container > ul.steps li.complete .chevron:before {
  border-left: 14px solid #f5f5f5;
}
.fuelux .wizard > ul.steps li.active,
.fuelux .wizard > .steps-container > ul.steps li.active {
  background: #eef7fb;
  color: #3a87ad;
  cursor: default;
}
.fuelux .wizard > ul.steps li.active .chevron:before,
.fuelux .wizard > .steps-container > ul.steps li.active .chevron:before {
  border-left: 14px solid #eef7fb;
}
.fuelux .wizard > ul.steps li.active .badge,
.fuelux .wizard > .steps-container > ul.steps li.active .badge {
  background-color: #3a87ad;
}
.fuelux .wizard > ul.steps li .badge,
.fuelux .wizard > .steps-container > ul.steps li .badge {
  margin-right: 8px;
}
.fuelux .wizard > ul.steps li .badge-success,
.fuelux .wizard > .steps-container > ul.steps li .badge-success {
  background-color: #468847;
}
.fuelux .wizard > ul.steps li:first-child,
.fuelux .wizard > .steps-container > ul.steps li:first-child {
  border-radius: 4px 0 0 0;
  padding-left: 20px;
}
.fuelux .wizard.rtl {
  direction: rtl;
}
.fuelux .wizard.rtl > ul.steps,
.fuelux .wizard.rtl > .steps-container > ul.steps {
  right: 0;
  left: auto;
  float: right;
}
.fuelux .wizard.rtl > ul.steps.previous-disabled li.complete:hover .chevron:before,
.fuelux .wizard.rtl > .steps-container > ul.steps.previous-disabled li.complete:hover .chevron:before {
  border-right-color: #f5f5f5;
}
.fuelux .wizard.rtl > ul.steps li,
.fuelux .wizard.rtl > .steps-container > ul.steps li {
  float: right;
}
.fuelux .wizard.rtl > ul.steps li .chevron,
.fuelux .wizard.rtl > .steps-container > ul.steps li .chevron {
  right: auto;
  left: -14px;
  border-right: 14px solid #cccccc;
  border-left: 0;
}
.fuelux .wizard.rtl > ul.steps li .chevron:before,
.fuelux .wizard.rtl > .steps-container > ul.steps li .chevron:before {
  right: auto;
  left: 1px;
  border-right: 14px solid #eeeeee;
  border-left: 0;
}
.fuelux .wizard.rtl > ul.steps li.active .chevron:before,
.fuelux .wizard.rtl > .steps-container > ul.steps li.active .chevron:before {
  border-right: 14px solid #eef7fb;
}
.fuelux .wizard.rtl > ul.steps li.complete .chevron:before,
.fuelux .wizard.rtl > .steps-container > ul.steps li.complete .chevron:before {
  border-right: 14px solid #f5f5f5;
}
.fuelux .wizard.rtl > ul.steps li.complete:hover .chevron:before,
.fuelux .wizard.rtl > .steps-container > ul.steps li.complete:hover .chevron:before {
  border-right: 14px solid #e2eaee;
  border-left: none;
}
.fuelux .wizard.rtl > ul.steps li .badge,
.fuelux .wizard.rtl > .steps-container > ul.steps li .badge {
  margin-left: 8px;
}
.fuelux .wizard.rtl > .actions {
  right: auto;
  left: 0;
  float: left;
  border-left: none;
  border-right: 1px solid #d4d4d4;
  border-radius: 4px 0 0 0;
}
.fuelux .wizard.rtl > .actions .btn-prev span {
  margin-left: 5px;
  margin-right: 0;
}
.fuelux .wizard.rtl > .actions .btn-next span {
  margin-left: 0;
  margin-right: 5px;
}
.fuelux .wizard > .actions {
  z-index: 1000;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 46px;
  float: right;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  background-color: #e6e6e6;
  border-left: 1px solid #d4d4d4;
  border-radius: 0 4px 0 0;
}
.fuelux .wizard > .actions a {
  line-height: 45px;
  font-size: 12px;
  margin-right: 8px;
}
.fuelux .wizard > .actions .btn-prev[disabled] {
  cursor: not-allowed;
}
.fuelux .wizard > .actions .btn-prev span {
  margin-right: 5px;
}
.fuelux .wizard > .actions .btn-next[disabled] {
  cursor: not-allowed;
}
.fuelux .wizard > .actions .btn-next span {
  margin-left: 5px;
}
.fuelux .wizard .step-content {
  border-top: 1px solid #cccccc;
  padding: 10px;
  float: left;
  width: 100%;
}
.fuelux .wizard .step-content .step-pane {
  display: none;
}
.fuelux .wizard .step-content > .active {
  display: block;
}
.fuelux .wizard .step-content > .active .btn-group .active {
  display: inline-block;
}
.fuelux .wizard.complete > .actions .btn-next .glyphicon {
  display: none;
}
.fuelux .serif {
  font-family: Georgia, "Times New Roman", Times, serif;
}
.fuelux .monospace {
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
}
.fuelux .text-sm {
  font-size: 14px * 0.85;
}
.fuelux .text-lg {
  font-size: 14px * 1.25;
}
.fuelux .padding-xs {
  padding: 5px;
}
.fuelux .padding-xs-horizontal {
  padding: 0 5px;
}
.fuelux .padding-xs-vertical {
  padding: 5px 0;
}
.fuelux .padding-xs-top {
  padding-top: 5px;
}
.fuelux .padding-xs-bottom {
  padding-bottom: 5px;
}
.fuelux .padding-xs-left {
  padding-left: 5px;
}
.fuelux .padding-xs-right {
  padding-right: 5px;
}
.fuelux .padding-sm {
  padding: 10px;
}
.fuelux .padding-sm-horizontal {
  padding: 0 10px;
}
.fuelux .padding-sm-vertical {
  padding: 10px 0;
}
.fuelux .padding-sm-top {
  padding-top: 10px;
}
.fuelux .padding-sm-bottom {
  padding-bottom: 10px;
}
.fuelux .padding-sm-left {
  padding-left: 10px;
}
.fuelux .padding-sm-right {
  padding-right: 10px;
}
.fuelux .padding-md {
  padding: 15px;
}
.fuelux .padding-md-horizontal {
  padding: 0 15px;
}
.fuelux .padding-md-vertical {
  padding: 15px 0;
}
.fuelux .padding-md-top {
  padding-top: 15px;
}
.fuelux .padding-md-bottom {
  padding-bottom: 15px;
}
.fuelux .padding-md-left {
  padding-left: 15px;
}
.fuelux .padding-md-right {
  padding-right: 15px;
}
.fuelux .padding-lg {
  padding: 20px;
}
.fuelux .padding-lg-horizontal {
  padding: 0 20px;
}
.fuelux .padding-lg-vertical {
  padding: 20px 0;
}
.fuelux .padding-lg-top {
  padding-top: 20px;
}
.fuelux .padding-lg-bottom {
  padding-bottom: 20px;
}
.fuelux .padding-lg-left {
  padding-left: 20px;
}
.fuelux .padding-lg-right {
  padding-right: 20px;
}
.fuelux .margin-xs {
  margin: 5px;
}
.fuelux .margin-xs-horizontal {
  margin: 0 5px;
}
.fuelux .margin-xs-vertical {
  margin: 5px 0;
}
.fuelux .margin-xs-top {
  margin-top: 5px;
}
.fuelux .margin-xs-bottom {
  margin-bottom: 5px;
}
.fuelux .margin-xs-left {
  margin-left: 5px;
}
.fuelux .margin-xs-right {
  margin-right: 5px;
}
.fuelux .margin-sm {
  margin: 10px;
}
.fuelux .margin-sm-horizontal {
  margin: 0 10px;
}
.fuelux .margin-sm-vertical {
  margin: 10px 0;
}
.fuelux .margin-sm-top {
  margin-top: 10px;
}
.fuelux .margin-sm-bottom {
  margin-bottom: 10px;
}
.fuelux .margin-sm-left {
  margin-left: 10px;
}
.fuelux .margin-sm-right {
  margin-right: 10px;
}
.fuelux .margin-md {
  margin: 15px;
}
.fuelux .margin-md-horizontal {
  margin: 0 15px;
}
.fuelux .margin-md-vertical {
  margin: 15px 0;
}
.fuelux .margin-md-top {
  margin-top: 15px;
}
.fuelux .margin-md-bottom {
  margin-bottom: 15px;
}
.fuelux .margin-md-left {
  margin-left: 15px;
}
.fuelux .margin-md-right {
  margin-right: 15px;
}
.fuelux .margin-lg {
  margin: 20px;
}
.fuelux .margin-lg-horizontal {
  margin: 0 20px;
}
.fuelux .margin-lg-vertical {
  margin: 20px 0;
}
.fuelux .margin-lg-top {
  margin-top: 20px;
}
.fuelux .margin-lg-bottom {
  margin-bottom: 20px;
}
.fuelux .margin-lg-left {
  margin-left: 20px;
}
.fuelux .margin-lg-right {
  margin-right: 20px;
}
.fuelux .data-label {
  text-transform: uppercase;
  font-size: 10px;
  margin-bottom: 2px;
  text-shadow: 0 1px #FFF;
  color: #666666;
}
.fuelux .data-value {
  font-size: 14px;
}
.fuelux .section-heading {
  font-size: 16px;
  color: #333333;
  margin: 0 0 10px;
  padding: 0;
  text-shadow: 0 1px #FFF;
}
.fuelux .truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*# sourceMappingURL=fuelux.css.map */